<template>
  <div class="content-wrap">
    <!------------------------------ 左部分区域 start ------------------------------>
    <div class="content-left">

      <div class="content-item video-monitoring">
        <div class="content-title">
          <span>设备联网接入率</span>
        </div>
        <div class="content-charts">
          <div id="device-live" style="width: 100%; height: 100%" v-loading="!proportion.series.data.length"
            element-loading-background="transparent"></div>
        </div>
      </div>
      <!-- 水体监测 -->
      <div class="content-item traceability-analysis">
        <div class="content-title">
          <span>设备数年增长量</span>
        </div>
        <div class="content-charts">
          <div id="traceability-analysis-send1" style="width: 100%; height: 100%"
            v-loading="!traceability.series[0].length" element-loading-background="transparent"></div>
        </div>
      </div>
      <!-- 网箱1 -->
      <div class="content-item warning-information">
        <div class="content-title">
          <span>设备活跃情况Top</span>
        </div>
        <div class="content-charts lively-chart">
          <el-carousel height="215px" v-if="livelys.length" :autoplay="!false" direction="vertical">
            <el-carousel-item v-for="item in livelys" :key="item.id">
              <div class="title" :title="item.deviceName">{{ item.deviceName }} <span>{{ item.machineNo }}</span></div>
              <div class="livelys-main">
                <div :title="item.deviceType">类型：{{ item.deviceType }}</div>
                <div :title="item.total">Monthly output：<span style="font-size: 0.28rem;color: #2aedff;font-weight: bold;">{{ item.total }}</span>
                </div>
                <div>Model：{{ item.deviceModel }}</div>
                <div :title="`${item.country} ${item.province} ${item.city}`">所属区域：{{
                  `${item.country} ${item.province} ${item.city}`
                }}</div>
              </div>
              <!-- <div class="sub-title">生产情况</div> -->
              <div class="order-item">
                <div class="charts-item" v-for="ele in item.productionDatas">
                  <div class="item-val">
                    {{ ele.quantity }}
                  </div>
                  <div class="item-txt">PCS/{{ ele.productName }}</div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>

          <p v-else class="no-data">暂无数据</p>
        </div>
      </div>
    </div>
    <!------------------------------ 左部分区域 end ------------------------------>

    <!------------------------------ 中部分区域 start ------------------------------>
    <div class="content-middle">
      <!-- 年总产值 -->
      <div class="total-num">
        <div class="charts-item">
          <div class="item-val">
            <CountTo :key="1" :start-val="0" :end-val="+infoData.onlineQty || 0" :duration="2500"></CountTo>
          </div>
          <div class="item-txt">Set/设备在线</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo :key="2" :start-val="0" :end-val="+infoData.offlineQty || 0" :duration="2500"></CountTo>
          </div>
          <div class="item-txt">Set/设备离线</div>
        </div>
        <div class="charts-item">
          <div class="item-val">
            <CountTo :key="20" :start-val="0" :end-val="+infoData.errorQty || 0" :duration="2500"></CountTo>
          </div>
          <div class="item-txt">Set/设备异常</div>
        </div>
      </div>
    </div>
    <!------------------------------ 中部分区域 end ------------------------------>

    <!------------------------------ 右部分区域 start ------------------------------>
    <div class="content-right">
      <!-- 全产业链可视化管理 -->
      <div class="content-item video-monitoring">
        <div class="content-title">
          <span>设备分布区域情况</span>
        </div>
        <div class="content-charts">
          <div id="proportion-imported-chart" style="width: 100%; height: 100%" v-loading="!proportion.series.data.length"
            element-loading-background="transparent"></div>
        </div>
      </div>

      <!-- 溯源分析 -->
      <div class="content-item traceability-analysis">
        <div class="content-title">
          <span>故障预测</span>
        </div>
        <div class="content-charts">
          <div id="traceability-analysis-send" style="width: 100%; height: 100%" v-loading="!conditions.xAxis.data.length"
            element-loading-background="transparent"></div>
        </div>
      </div>

      <!-- 预警信息 -->
      <div class="content-item warning-information">
        <div class="content-title">
          <span>订单生产情况</span>
        </div>
        <div class="content-charts">
          <div class="ls-title" v-if="queryWarning.length">
            <span>设备名称</span>
            <span>机器编号</span>
            <span style="width: 32%;">订单号</span>
            <span>产品名称</span>
            <span style="width: 10%;">QTY</span>
            <span>生产时间</span>
          </div>
          <div class="chart-item" v-if="queryWarning.length">
            <swiper :options="swiperOption01">
              <swiper-slide v-for="(ele, idx) in queryWarning" :key="idx">
                <div class="ls-item">
                  <span>{{ ele.deviceName }}</span>
                  <span>{{ ele.machineNo }}</span>
                  <span style="width: 32%;">{{ ele.orderNo }}</span>
                  <span>{{ ele.productName }}</span>
                  <span style="width: 10%;">{{ ele.quantity }}</span>
                  <span>{{ ele.endTime }}</span>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <p v-else class="no-data">暂无数据</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Vue from 'vue'
import { Progress, Carousel, CarouselItem } from 'element-ui'
Vue.use(Progress).use(Carousel).use(CarouselItem)

import CountFlop from '../../../components/count-to-component.vue'

import * as $echarts from 'echarts'
import { LogKeyName } from '../modules/static.js'
import CountTo from 'vue-count-to'
import moment from 'moment'
export default {
  name: 'digital-park',
  components: {
    CountFlop,
    CountTo,
    swiper,
    swiperSlide,
  },
  inject: ['app'],
  data() {
    return {
      tte: {
        '广东': 'Guangdong',
        '湖南': 'Hunan',
        '江苏': 'Jiangsu',
        '福建': 'Fujian',
        '江西': 'Jiangxi',
        '浙江': 'Zhejiang',
        '湖北': 'hubei',
        '天津': 'Tianjin',
        '贵州': 'Guizhou',
        '山东': 'Shandong',
        '河南': 'Henan',
        '黑龙江': 'Heilongjiang',
        '辽宁': 'Liaoning',
        '安徽': 'Anhui',
        '云南': 'Yunnan',
        '陕西': 'Shaanxi',
        '上海': 'Shanghai',
        '河北': 'Hebei',
        '内蒙古': 'Inner Mongolia',
        '吉林': 'Jilin',
        '北京': 'Beijing',
        '甘肃': 'Gansu',
        '广西': 'Guangxi',
        '重庆': 'Chongqing',
        '青海': 'Qinghai',
        '四川': 'Sichuan',
        '海南': 'Hainan',
        '山西': 'Shanxi',
        '紧急告警': 'Emergency alarm',
        '主要告警': 'Main alarm',
        '次要告警': 'Minor alarm',
        '提示告警': 'Prompt alarm',
      },
      proportion: {
        idName: 'proportion-imported-chart',
        series: {
          data: [],
        },
      },
      deviceLive: {
        idName: 'device-live',
        series: {
          data: [],
        },
      },
      dialogFormVisible: false,
      dialogChartVisible: false,
      infoData: {
        issueNumberList: [],
        queryCountByYearList: [],
      },
      queryWarning: [],
      livelys: [],
      boxName: '', // 当前显示的下标
      swiperOption01: {
        spaceBetween: 5,
        autoplay: {
          disableOnInteraction: false,
          delay: 1000, //1秒切换一次
        },
        slidesPerView: 4,
        direction: 'vertical',
      },
      swiperOption02: {
        // 所有的参数同 swiper 官方 api 参数
        spaceBetween: 5,
        autoplay: {
          disableOnInteraction: false,
          delay: 1000, //1秒切换一次
        },
        slidesPerView: 4,
        direction: 'vertical',
      },
      swiperOption: {
        mousewheel: {
          thresholdTime: 200,
        },
        // 所有的参数同 swiper 官方 api 参数
        spaceBetween: this.app.fontHandle(30) - 1,
        slidesPerView: 3,
        direction: 'vertical',
      },
      warnTxt: '',
      params: {},
      map: null,
      timerNum: this.$.formatDate(new Date(), 'yyyy-MM-dd hh:mm'),
      cityList: [],
      active: -1,
      conn: true,
      quotaList: [], // (水体监测)指标列表
      // 网箱1
      conditions: {
        idName: 'traceability-analysis-send',
        legend: {
          data: ['溶氧', 'pH', '水温'],
        },
        series: {
          alarm1: {
            name: '水温',
            data: []
          },
          alarm2: {
            name: '水温',
            data: []
          },
          alarm3: {
            name: '水温',
            data: []
          },
          alarm4: {
            name: '水温',
            data: []
          },
        },
        xAxis: {
          data: [],
          type: 'category',
          boundaryGap: false,
          axisLabel: {
            showMaxLabel: true,
            rotate: 45,
            interval: null,
          },
        },
        yAxis: {},
      },
      // 月查询次数
      traceability: {
        idName: 'traceability-analysis-send1',
        title: {
          text: '月查询次数',
        },
        legend: {
          data: ['月查询次数'],
        },
        xAxis: {
          data: [
            '12月',
          ],
        },
        series: [
          []
        ],
      },

      // 点击量
      clickNumChart: {
        idName: 'click-num-chart',
        title: {
          text: '点击量',
        },
        legend: {
          data: ['点击量'],
        },
        xAxis: {
          data: [
            '12月',
          ],
        },
        series: [
          [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 30]
        ],
      },
      // 月发放溯源码数量
      traceabilitySend: {
        idName: 'traceability-analysis-send',
        title: {
          text: '月查询次数',
        },
        legend: {
          data: ['月查询次数'],
        },
        xAxis: {
          data: [
            '12月',
          ],
        },
        series: [
          [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 30]
        ],
      },
      videoList: null, // 视频列表
      videoUrl: null, // 视频路径
      videoItem: null, // 视频对象
      showVideo: true, // 是否展示视频
      videoSwiperCount: 0, // 视频上下点击滑动计数
      itemheight: 0,
      curIdx: 0,
    }
  },
  computed: {},
  methods: {
    clickIdx(box) {
    },
    // 项目概况 圆饼图
    // 折线图
    seedlingBreedingChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe', '#5B49FF'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: obj.legend.data,
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
          borderRadius: fontHandle(200), // need edit
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(34, 'px'),
          top: fontHandle(16, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: obj.series.alarm1.name,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#F553FB',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#F06CFA',
            },
            data: obj.series.alarm1.data,
            smooth: true,
            symbolSize: 0,
            [obj.idName ? 'areaStyle' : '']: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255, 100, 235, 0.3)',
                    },
                    {
                      offset: 0.5,
                      color: 'rgba(255, 100, 235, 0.24)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
          {
            name: obj.series.alarm2.name,
            data: obj.series.alarm2.data,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#5B49FF',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#5B49FF',
            },
            smooth: true,
            symbolSize: 0,
            [obj.idName ? 'areaStyle' : '']: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(60, 184, 255, 0.35)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
          {
            name: obj.series.alarm3.name,
            data: obj.series.alarm3.data,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#3CB8FF',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#3CB8FF',
            },
            smooth: true,
            symbolSize: 0,
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(60, 184, 255, 0.35)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
          {
            name: obj.series.alarm4.name,
            data: obj.series.alarm4.data,
            type: 'line',
            itemStyle: {
              normal: {
                color: '#9287FF',
              },
            },
            lineStyle: {
              width: 2.5,
              color: '#9287FF',
            },
            smooth: true,
            symbolSize: 0,
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(60, 184, 255, 0.35)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 255, 255, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(10,219,250, 0.5)', //阴影颜色
                shadowBlur: 0, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
          },
        ],
      }
      chart.setOption(option)
    },
    // 折线图2
    traceabilityAnalysisChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let seriescolor = ['#5B49FF', '#F55AFB']
      let series = obj.legend.data.map((ele, idx) => {
        return {
          name: ele,
          type: 'line',
          itemStyle: {
            normal: {
              color: seriescolor[idx],
            },
          },
          lineStyle: {
            width: 2.5,
            color: seriescolor[idx],
          },
          data: obj.series[idx],
          smooth: true,
          symbolSize: 0,
        }
      })
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: obj.legend.data,
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(34, 'px'),
          top: fontHandle(25, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series,
      }
      chart.setOption(option)
    },
    /**
     * @brief: 生成过去24h/48h/72h的空数据数组
     * @author: Scarlett Xiao
     * @Date: 2021-10-19 17:57:17
     * @param {*} nowTime 当前时间(YYYY-MM-DD HH:mm)
     * @param {number} hour 多少小时(24)
     */
    createEmptyDataArrByHour(nowTime, hour) {
      const count = hour * 60 // 过去多少小时乘60分钟
      const DATA = []
      DATA.push({
        updateTime: nowTime,
        oxygen: '',
        ph: '',
        water: '',
        isData: false,
      })
      while (DATA.length < count) {
        const date = new Date(DATA[0].updateTime.replace(/-/g, '/'))
        // 获取前一分钟的时间
        const prevMinutes = moment(date.getTime())
          .subtract(1, 'minutes')
          .format('YYYY-MM-DD HH:mm')
        DATA.unshift({
          updateTime: prevMinutes,
          oxygen: '',
          ph: '',
          water: '',
          isData: false,
        })
      }
      return DATA
    },
    getLog(month) {
      this.$axios('/zhanjiang-admin/systemLog/monthDetail?queryDate=' + month + '-1')
        .then((res) => {
          console.log('res', res)
          const data = res.data
          this.clickNumChart.xAxis.data = []
          this.clickNumChart.series[0] = []
          data.forEach((item) => {
            console.log('item', item, LogKeyName[item.moduleType])
            this.clickNumChart.xAxis.data.push(LogKeyName[item.moduleType])
            this.clickNumChart.series[0].push(item.clickCount)
          })
          this.$nextTick(() => {

            this.clickCountChart(this.clickNumChart)
          })

        })
        .catch(() => { })
    },

    aquaticTrendChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: ['Equipment Quantity'],
          bottom: fontHandle(6),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(12),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
          borderRadius: fontHandle(200), // need edit
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          top: fontHandle(25, 'px'),
          bottom: fontHandle(30, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(12),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 3,
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(12),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: 'Equipment Quantity',
            type: 'bar',
            yAxisIndex: 0,
            data: obj.series[0],
            barWidth: fontHandle(15),
            barGap: 1,
            itemStyle: {
              normal: {
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#6BE0BD',
                    },
                    {
                      offset: 0.5,
                      color: '#089DDD',
                    },
                    {
                      offset: 1,
                      color: '#FE69FB',
                    },
                  ],
                  false
                ),
                barBorderRadius: fontHandle(100),
              },
            },
            markLine: {
              data: [{
                type: "average",
                name: "平均值"
              }],
              symbol: ["none", "none"],
              position: "insideTopCenter",
              silent: !1,
              itemStyle: {
                normal: {
                  lineStyle: {
                    type: [10, 10],
                    color: "#b2f4f8"
                  },
                  label: {
                    show: !0,
                    color: "#9BA7BF",
                    position: "end"
                  }
                }
              }
            },
          },
        ],
      }
      chart.setOption(option)
    },
    // 折线图
    clickCountChart(obj) {
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        color: ['#b2f4f8', '#ffcb4d', '#12fffe'],
        legend: {
          icon: 'roundRect', // 有点圆角的图例标志
          data: ['点击量'],
          bottom: fontHandle(10),
          textStyle: {
            color: '#9BA7BF',
            fontSize: fontHandle(14),
          },
          itemWidth: fontHandle(10),
          itemHeight: fontHandle(10),
          itemGap: fontHandle(35),
          borderRadius: fontHandle(200), // need edit
        },
        grid: {
          left: fontHandle(11, 'px'),
          right: fontHandle(18, 'px'),
          bottom: fontHandle(50, 'px'),
          top: fontHandle(25, 'px'),
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: obj.xAxis.data,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#46494A',
                opacity: 1,
                width: 1,
                type: 'solid',
              },
            },
            axisTick: {
              show: false,
            },
            // x轴下方样式
            axisLabel: {
              show: true,

              textStyle: {
                color: '#838DA1',
                fontSize: fontHandle(14),
              },
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitNumber: 3,
            nameTextStyle: {
              color: '#838DA1',
            },
            offset: 0,
            // Y轴下方样式
            axisLabel: {
              color: '#838DA1',
              fontSize: fontHandle(14),
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: [10, 10],
                color: '#46494A',
                width: 1,
              },
            },
          },
        ],
        series: [
          {
            name: '点击量',
            type: 'bar',
            yAxisIndex: 0,
            data: obj.series[0],
            barWidth: fontHandle(15),
            barGap: 1,
            itemStyle: {
              normal: {
                color: new $echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#6BE0BD',
                    },
                    {
                      offset: 0.5,
                      color: '#089DDD',
                    },
                    {
                      offset: 1,
                      color: '#FE69FB',
                    },
                  ],
                  false
                ),
                barBorderRadius: fontHandle(100),
              },
            },
          },
        ],
      }
      chart.setOption(option)
    },
    openClickNumDialog(month) {
      this.dialogChartVisible = true
      this.getLog(month)
      this.getYearLog()
    },

    proportionImportedChart(obj) {
      let sum = 0
      obj.series.data.forEach(r => {
        sum += r.value
      })
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        title: [
          {
            text: "{a|" + sum + "}",
            x: "36%",
            y: "32%",
            textAlign: "center",
            textStyle: {
              fontSize: "30",
              fontWeight: "700",
              color: "#fff",
              textAlign: "center",
              textShadowColor: "#000",
              textShadowBlur: "1",
              textShadowOffsetX: 2,
              textShadowOffsetY: 2,
              rich: {
                a: {
                  fontSize: 48,
                  color: "#29EEF3",
                  fontWeight: "700",
                },

                c: {
                  fontSize: 20,
                  color: "#ffffff",
                  padding: [8, 0, 0, 0]
                },
              },
            },
          },
          {
            text: "All Devices",
            x: "36%",
            y: "56%",
            textAlign: "center",
            textStyle: {
              fontSize: "14",
              fontWeight: "600",
              color: "#fff",
              textAlign: "center",
              textShadowColor: "#000",
              textShadowBlur: "1",
              textShadowOffsetX: 1,
              textShadowOffsetY: 1,
            },
          },
        ],
        color: [
          '#5DE9C4',
          '#E654EB',
          '#5442FF',
          '#EBA447',
          '#4898FB',
          '#0CCCCE',
        ],
        tooltip: {
          trigger: 'item',
        },
        legend: {
          itemWidth: fontHandle(20),
          itemHeight: fontHandle(20),
          type: 'scroll',
          orient: 'vertical',
          right: '4%',
          top: '42%',
          itemGap: fontHandle(22),
          textStyle: {
            color: '#FFFFFF',
            fontSize: fontHandle(20),
            fontWeight: 'bold',
            rich: {
              a: {
                color: '#999',
                width: fontHandle(100),
              },
              b: {
                color: '#23c3ff',
                width: fontHandle(80),
                align: 'right',
              },
              c: {
                color: '#ffffff',
                width: fontHandle(100),
                align: 'left',
              },
            },
          },
          formatter: (name) => {
            const item = obj.series.data.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            return "{name|" + name + "}：" + "{value|" + item.value + "}";
          },
        },
        series: [
          {
            itemStyle: {
              borderWidth: 2, //设置border的宽度有多大
              borderColor: 'rgba(0,0,0,0.2)',
              normal: {
                labelLine: {
                  show: true,
                  color: '#ff0',
                },
              },
            },
            smooth: true,
            center: ['36%', '50%'],
            name: '',
            type: 'pie',
            radius: ['54%', '70%'],
            avoidLabelOverlap: true, //默认开启，在标签拥挤重叠的情况下会挪动各个标签的位置，防止标签间的重叠。
            data: obj.series.data,
            label: {
              show: true,
              formatter: '\n{b}\n\n{d}%',
              position: 'outside',
              color: '#9BA7BF',
              fontSize: fontHandle(12),
            },

            emphasis: {
              scaleSize: 4,
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              normal: {
                show: true,
                lineStyle: {
                  type: 'solid',
                  color: '#9BA7BF',
                },
              },
            },
          },
        ],
      }
      chart.setOption(option)
      chart.on("mouseover", function (e) {
        var t = chart.getOption();
        t.title[0].text = "{a|".concat(e.data.value, "}"),

          t.title[1].text = "".concat(e.data.name),
          chart.setOption(t)
      })
    },
    recentlyOrder(obj) {
      let sum = 0
      obj.series.data.forEach(r => {
        sum += r.value
      })
      let fontHandle = this.app.fontHandle
      let chart = $echarts.init(document.getElementById(obj.idName))
      let option = {
        backgroundColor: 'transparent',
        title: [
          {
            // text: "75.0%",
            text: "{a|" + sum + "}",
            x: "36%",
            y: "32%",
            textAlign: "center",
            textStyle: {
              fontSize: "30",
              fontWeight: "700",
              color: "#fff",
              textAlign: "center",
              textShadowColor: "#000",
              textShadowBlur: "1",
              textShadowOffsetX: 2,
              textShadowOffsetY: 2,
              rich: {
                a: {
                  fontSize: 48,
                  color: "#29EEF3",
                  fontWeight: "700",
                },

                c: {
                  fontSize: 20,
                  color: "#ffffff",
                  padding: [8, 0, 0, 0]
                },
              },
            },
          },
          {
            text: "All Devices",
            x: "36%",
            y: "56%",
            textAlign: "center",
            textStyle: {
              fontSize: "14",
              fontWeight: "600",
              color: "#fff",
              textAlign: "center",
              textShadowColor: "#000",
              textShadowBlur: "1",
              textShadowOffsetX: 1,
              textShadowOffsetY: 1,
            },
          },
        ],
        color: [
          '#5DE9C4',
          '#E654EB',
          '#5442FF',
          '#EBA447',
          '#4898FB',
          '#0CCCCE',
        ],
        tooltip: {
          trigger: 'item',
        },
        legend: {
          itemWidth: fontHandle(20),
          itemHeight: fontHandle(20),
          type: 'scroll',
          orient: 'vertical',
          right: '-5%',
          top: fontHandle(26),
          itemGap: fontHandle(22),
          textStyle: {
            color: '#FFFFFF',
            fontSize: fontHandle(20),
            fontWeight: 'bold',
            rich: {
              a: {
                color: '#999',
                width: fontHandle(100),
              },
              b: {
                color: '#23c3ff',
                width: fontHandle(80),
                align: 'right',
              },
              c: {
                color: '#ffffff',
                width: fontHandle(100),
                align: 'left',
              },
            },
          },
          formatter: (name) => {
            const item = obj.series.data.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            return "{name|" + name + "}：" + "{value|" + item.value + "}";
          },
        },
        series: [
          {
            itemStyle: {
              borderWidth: 2, //设置border的宽度有多大
              borderColor: 'rgba(0,0,0,0.2)',
              normal: {
                labelLine: {
                  show: true,
                  color: '#ff0',
                },
              },
            },
            smooth: true,
            center: ['36%', '50%'],
            name: '',
            type: 'pie',
            radius: ['54%', '70%'],
            avoidLabelOverlap: true, //默认开启，在标签拥挤重叠的情况下会挪动各个标签的位置，防止标签间的重叠。
            data: obj.series.data,
            label: {
              show: true,
              formatter: '\n{b}\n\n{d}%',
              position: 'outside',
              color: '#9BA7BF',
              fontSize: fontHandle(12),
            },

            emphasis: {
              scaleSize: 4,
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              normal: {
                show: true,
                lineStyle: {
                  type: 'solid',
                  color: '#9BA7BF',
                },
              },
            },
          },
        ],
      }
      chart.setOption(option)
      chart.on("mouseover", function (e) {
        var t = chart.getOption();
        t.title[0].text = "{a|".concat(e.data.value, "}"),
          t.title[1].text = "".concat(e.data.name),
          chart.setOption(t)
      })
    },
    turnto(t) {
      return new Promise((e, i) => {
        window.translate.request.translateText(t, function (data) {
          //打印翻译结果
          console.log(t, data.text[0]);
          if (data.result == '1') {
            e(data.text[0])
          } else {
            i(data.result)
          }
        });

      })
    },
    async one() {
      // 获取物联网+接入情况
      await this.$axios('/iot-device/center/dataScreen/getIotPlusAccessSituation').then(r => {
        this.deviceLive.series.data = [{ name: 'Connected', value: r.data.accessedQty }, { name: 'Disconnected', value: r.data.deviceTotal - r.data.accessedQty }]
        this.deviceLive.series.data.forEach(e => {
          e.itemStyle = {
            borderRadius: 10,
            shadowBlur: 5,
            shadowOffsetY: 0,
            shadowOffsetX: 0,
            borderWidth: 2,
          }
        })
        this.proportionImportedChart(this.deviceLive)
      })

      // 查询设备国家地区分布列表
      await this.$axios('/iot-device/center/dataScreen/listDeviceRegionalDistribution').then(r => {
        this.proportion.series.data = r.data[0].provinceVo.map((ele) => {
          ele.value = ele.quantity
          ele.name = this.tte[ele.province]
          ele.itemStyle = {
            borderRadius: 10,
            shadowBlur: 5,
            shadowOffsetY: 0,
            shadowOffsetX: 0,
            borderWidth: 2,
          }
          return ele
        })
        this.recentlyOrder(this.proportion)

        setTimeout(() => {

          this.two()
        }, 200);
      })
    },
    async two() {
      // // 查询设备年度增长量列表
      await this.$axios('/iot-device/center/dataScreen/getDeviceStateSums').then(r => {
        this.infoData = r.data
      })

      // // 查询设备故障预测
      await this.$axios({ url: '/iot-device/center/dataScreen/listDeviceFaultPrediction', method: 'post', data: { recentDays: 20 } }).then(r => {
        let xData = []
        let legend = []
        r.data.forEach((item, i) => {
          legend.push(this.tte[item.alarmLevel])
          this.conditions.series[`alarm${i + 1}`].name = this.tte[item.alarmLevel]
          this.conditions.series[`alarm${i + 1}`].data = []
          item.dateCountVos.forEach(ele => {
            this.conditions.series[`alarm${i + 1}`].data.push(ele.quantity)
            if (xData.length < item.dateCountVos.length) {
              xData.push(ele.date)
            }
          })
        })
        this.conditions.legend = legend
        this.conditions.xAxis.data = xData
        this.seedlingBreedingChart(this.conditions)
        setTimeout(() => {
          this.three()
        }, 200);
      })
    },
    async three() {

      // // 查询设备年度增长量列表
      await this.$axios('/iot-device/center/dataScreen/listDeviceAnnualGrowth?yearSize=10').then(r => {

        this.traceability.xAxis.data = []
        this.traceability.series[0] = []
        r.data.forEach(e => {
          this.traceability.xAxis.data.push(e.year)
          this.traceability.series[0].push(e.growth)

        })
        this.aquaticTrendChart(this.traceability)
      })
      // // 查询设备活跃榜单列表
      await this.$axios({ url: '/iot-device/center/dataScreen/listDeviceLivelyTop', method: 'post', data: { topSize: 60, recentDays: 30 } }).then(r => {

        this.livelys = r.data
      })

      // // 查询设备最新生产订单列表
      await this.$axios({ url: '/iot-device/center/dataScreen/listDeviceLatestProduction', method: 'post', data: { recentDays: 10 } }).then(r => {
        this.queryWarning = r.data
      })

      window.translate.language.getCurrent() == 'chinese_simplified' && window.translate.changeLanguage('english')
    }
  },
  async mounted() {
    this.one()

  },
}
</script>
<style lang="less" scoped>
@import './zjl.less';

.no-data {
  display: flex;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 16px;

  align-items: center;
  justify-content: center;
}
</style>
